<template>
  <v-main :class="desktop ? 'tway-background-curves-svg' : 'tway-background-curves-svg-mobile'">
    <v-container v-if="!validate" fill-height :style="!desktop ? 'padding-top: 120px !important' : ''">
      <v-row justify="center">
        <v-card :width="desktop ? '75%' : '90%'" class="welcome-card">
          <v-form v-model="valid" ref="form">
            <grid
              :class-left="(desktop ? 'registration-form-border-right' : '') + ' pa-0'"
              class-right="px-8 pb-10 pt-0 py-md-5 px-md-8 py-lg-10 px-lg-12"
            >
              <template v-slot:left>
                <div class="welcome-container" :class="desktop ? 'tway-violet-dark' : ''">
                  <div
                    style="z-index: 10"
                    class="full-height tway-violet-dark white--text px-5 pt-10 pb-0 py-md-6 px-md-5 py-lg-10 px-lg-10"
                  >
                    <div class="mb-4">
                      <v-row class="ma-0" :justify="mobile ? 'center' : 'start'">
                        <span class="text-h5 text-md-h5 text-lg-h4"><strong>Sólo falta tu contraseña...</strong></span>
                      </v-row>
                    </div>
                    <div class="mb-4" v-if="desktop">
                      <span>Debe incluir:</span>
                      <ul class="mb-3" />
                      <ul class="white--text">
                        <li>Un mínimo de nueve caracteres</li>
                        <li>Al menos un número</li>
                        <li>Al menos una letra mayúscula</li>
                        <li>Al menos una letra minúscula</li>
                        <li>Al menos un caracter especial (!, @, #, $, %, ^, &, *)</li>
                      </ul>
                    </div>
                  </div>
                  <svg v-if="mobile" style="margin-top: -5vw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path
                      fill="#4A148C"
                      fill-opacity="1"
                      d="M0,256L60,240C120,224,240,192,360,176C480,160,600,160,720,176C840,192,960,224,1080,229.3C1200,235,1320,213,1380,202.7L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
                    />
                  </svg>
                </div>
              </template>
              <template v-slot:right>
                <v-row justify="center" class="mb-4 ma-0">
                  <span class="text-h5 text-md-h4">Crea tu contraseña</span>
                </v-row>
                <div>
                  <v-text-field
                    label="Define tu contraseña"
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    :rules="[required('Contraseña'), validatePassword()]"
                    required
                    counter
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                  />
                </div>
                <div class="mb-4">
                  <v-text-field
                    label="Confirma tu contraseña"
                    name="confirmPassword"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="passwordConfirmation"
                    :rules="[required('confirmación de contraseña'), validatePassword()]"
                    :error-messages="passwordMatchError()"
                    required
                    counter
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                  />
                </div>
                <div class="mb-4" v-if="!desktop">
                  <v-row class="ma-0 pa-0" justify="center">
                    <span class="text-center">Debe incluir:</span>
                  </v-row>
                  <ul class="mb-4" />
                  <ul>
                    <li>Un mínimo de nueve caracteres</li>
                    <li>Al menos un número</li>
                    <li>Al menos una letra mayúscula</li>
                    <li>Al menos una letra minúscula</li>
                    <li>Al menos un caracter especial (!, @, #, $, %, ^, &, *)</li>
                  </ul>
                </div>
                <div class="mb-4">
                  <v-row class="ma-0 pa-0" justify="center">
                    <v-btn
                      rounded
                      color="tway-violet"
                      class="button-text white--text"
                      :disabled="!valid"
                      @click="activateUser"
                      id="login-start-transformation"
                    >
                      ¡Empieza la transformación!
                    </v-btn>
                  </v-row>
                </div>
              </template>
            </grid>
          </v-form>
        </v-card>
      </v-row>
    </v-container>
  </v-main>
</template>

<style lang="scss" scoped>
.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.welcome-card {
  border-radius: 10px;
  overflow: hidden;
  max-width: 900px !important;
}
.no-account-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-account-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import { mapActions } from "vuex";
import ValidatorsService from "@/services/validatorsService";
import RowTwo from "@/components/grid/RowTwo.vue";
import loginService from "@/services/loginService";

export default {
  name: "ActivationForm",
  components: {
    grid: RowTwo,
  },
  data: () => ({
    user: {
      name: "",
      email: "",
    },
    userId: "",
    valid: true,
    password: null,
    passwordConfirmation: null,
    showPassword: false,
    validate: true,
  }),

  created() {
    localStorage.removeItem("accessToken");

    this.userId = this.$route.params.id;
    this.validateUser();
  },

  methods: {
    required: (propertyType) => ValidatorsService.required(propertyType),
    validatePassword: () => ValidatorsService.validatePassword(),
    passwordMatchError: function () {
      return this.password === this.passwordConfirmation ? "" : "Contraseñas deben ser iguales";
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$router.replace({ name: "home" });
      }
    },
    ...mapActions("authentication", ["doActivate"]),
    activateUser() {
      if (this.password === this.passwordConfirmation) {
        this.doActivate({
          userId: this.userId,
          password: this.password,
        });
        this.$router.replace({ name: "login" });
      }
    },
    validateUser() {
      loginService
        .validateUser(this.userId)
        .then((validate) => {
          this.validate = validate;
          if (this.validate) {
            this.$router.replace({ name: "login" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
